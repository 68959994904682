import React from "react";
import { Navigate, Route } from "react-router-dom";
import type { RouteProps } from "react-router"; // Import type from react-router
import { useAuth } from "../../auth/contexts/AuthProvider";

type PrivateRouteProps = {
  roles?: string[];
  element: React.ReactElement;
} & Omit<RouteProps, "element">;

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  roles,
  element,
  ...routeProps
}) => {
  const { hasRole, userInfo, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={`/${process.env.PUBLIC_URL}/login`} />;
  }

  if (roles && !hasRole(roles)) {
    return <Navigate to={`/${process.env.PUBLIC_URL}/403`} />;
  }

  return <Route {...routeProps} element={element} />;
};

export default PrivateRoute;
