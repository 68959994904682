import axios from "axios";
import { useMutation } from "react-query";


const baseURL = process.env.REACT_APP_API_BASE_URL;

console.log("baseURL")

const login = async ({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<{ token: string; user: any }> => {
  try {
    console.log("Login API - Starting");
    console.log("baseURLdd",process.env.REACT_APP_API_BASE_URL )

    console.log("API Base URL:", baseURL);

    const response = await axios.post(`${baseURL}/api/auth/login`, {
      email,
      password
    });
    console.log("API Response received:", response);

    console.log("Login successful. Data:", response.data);
    localStorage.setItem("authToken", response.data.token);
    return response.data;
  } catch (error) {
    console.error("Login failed. Error details:", error);

    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Login failed");
    } else if (error instanceof Error) {
      throw new Error(`Login failed: ${error.message}`);
    } else {
      throw new Error("Login failed: An unknown error occurred");
    }
  }
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);
  return { isLoggingIn: isLoading, login: mutateAsync };
}
