import Logo from "./Logo";

const Loader = () => {
  return (
    <Logo
      size={100}
      sx={{
        "@keyframes pulse": {
          "0%": {
            opacity: 1
          },
          "50%": {
            opacity: 0.4
          },
          "100%": {
            opacity: 1
          }
        },
        animation: "pulse 1.5s ease-in-out 0.5s infinite",
        textAlign: "center",
        px: 3,
        py: 8
      }}
    />
  );
};

export default Loader;
